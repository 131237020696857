import {NotificationType} from "@/models/notificationType";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import {htmliFy} from "@/util/stringUtils";

export const getNotificationMessage=(
    notification:any,
    translateFct:any,
    authorReplacement:any,
    speachReplacement:any,
    abuserReplacement:any,
    commentingUserReplacement:any,
    parcoursReplacement:any,
    addDefaultStyle:any
) =>{
    return translateFct(getLibelleKey(notification.typeNotification), {
        author: htmliFy(authorReplacement, true),
        speach: htmliFy(speachReplacement, true),
        badge: addDefaultStyle(htmliFy(getBadge(notification), true)),
        resultatQuizz: htmliFy(speachReplacement, true),
        sondage: htmliFy(speachReplacement, true),
        conversation: htmliFy(speachReplacement, true),
        parcour: htmliFy(parcoursReplacement, true),
        userComment: htmliFy(commentingUserReplacement, true),
        abuserUser: htmliFy(abuserReplacement, true),
        topic: addDefaultStyle(htmliFy(getTopic(notification), true)),
        group: addDefaultStyle(htmliFy(getGroup(notification), true)),
        interpolation: { escapeValue: false }
    });
}


/**
 * méthode permettant de récupérer une clef de trad depuis un type de notification
 * @param {String} type
 * @return {String} libelle
 */
export const getLibelleKey=(type:NotificationType)=> {
    let libelle;
    switch (type) {
        case NotificationType.FORMATEUR_SPEACH_VALIDATE:
            libelle = "notification.formateur.workflow.validated.description";
            break;
        case NotificationType.FORMATEUR_SPEACH_REFUSED:
            libelle = "notification.formateur.workflow.rejected.description";
            break;
        case NotificationType.FORMATEUR_SPEACH_PUBLISHED:
            libelle = "notification.formateur.workflow.published.description";
            break;
        case NotificationType.FORMATEUR_NOUVEAU_APPRENANT:
            libelle = "notification.formateur.nouveau.apprenant.description";
            break;
        case NotificationType.FORMATEUR_NOUVEAU_COMMENTAIRE:
            libelle = "notification.formateur.nouveau.commentaire.description";
            break;
        case NotificationType.FORMATEUR_AJOUT_FAVORIS:
            libelle = "notification.formateur.nouveau.favoris.description";
            break;
        case NotificationType.FORMATEUR_PARTAGE_COUR:
            libelle = "notification.formateur.nouveau.partage.description";
            break;
        case NotificationType.FORMATEUR_REPONSE_EXAM:
            libelle = "notification.formateur.reponse.exam.description";
            break;
        case NotificationType.FORMATEUR_REPONSE_SONDAGE:
            libelle = "notification.formateur.reponse.sondage.description";
            break;
        case NotificationType.FORMATEUR_NOUVEAU_MESSAGE:
            libelle = "notification.formateur.nouveau.message.description";
            break;
        case NotificationType.FORMATEUR_NOUVEAU_COEDITION:
            libelle = "notification.formateur.nouveau.coedition.description";
            break;
        case NotificationType.FORMATEUR_OBTENTION_BADGE_COUR:
          libelle = "notification.formateur.nouveau.obtentionBadge.description";
          break;
        case NotificationType.FORMATEUR_SPEACH_DUE_DATE_EXPIRED:
          libelle = "notification.formateur.speach.dueDateReached.description";
          break;
        case NotificationType.ADMIN_NOUVEAU_APPRENANT:
            libelle = "notification.admin.nouveau.apprenant.description";
            break;
        case NotificationType.ADMIN_NOUVEAU_COUR_PUBLIE:
            libelle = "notification.admin.nouveau.cour.description";
            break;
        case NotificationType.ADMIN_NOUVEAU_PARCOUR_PUBLIE:
            libelle = "notification.admin.nouveau.parcour.description";
            break;
        case NotificationType.ADMIN_NOUVEAU_COMMENTAIRE:
            libelle = "notification.admin.nouveau.commentaire.description";
            break;
        case NotificationType.ADMIN_NOUVEAU_USER:
            libelle = "notification.admin.nouveau.user.description";
            break;
        case NotificationType.ADMIN_SIGNALER_ABUS:
            libelle = "notification.admin.signaler.abus.description";
            break;
        case NotificationType.ADMIN_SIGNALER_ABUS_SPEACH:
            libelle = "notification.admin.signaler.abus.speach.description";
            break;
        case NotificationType.ADMIN_OBTENTION_BADGE_COUR:
            libelle = "notification.admin.obtentionBadge.description";
            break;
        case NotificationType.ADMIN_SPEACH_DUE_DATE_EXPIRED:
          libelle = "notification.admin.speach.dueDateReached.description";
          break;
        case NotificationType.USER_NOUVEAU_MESSAGE:
            libelle = "notification.user.nouveau.message.description";
            break;
        case NotificationType.USER_NOUVEAU_AFFECTATION:
            libelle = "notification.user.nouveau.affectation.description";
            break;
        case NotificationType.USER_NOUVEAU_AFFECTATION_PARCOUR:
            libelle = "notification.user.nouveau.affectation.parcour.description";
            break;
        case NotificationType.USER_NOUVEAU_TOPIC_MESSAGE:
            libelle = "notification.user.nouveau.topicMessage.description";
            break;
        case NotificationType.USER_OBTENTION_BADGE_COUR:
            libelle = "notification.user.obtentionBadge.description";
            break;
        case NotificationType.USER_SPEACH_DUE_DATE_APPROACHING:
          libelle = "notification.user.speach.dueDateApproaching.description";
          break;
        case NotificationType.VALIDATEUR_SPEACH_TO_VALIDATE:
            libelle = "notification.validateur.workflow.toValidate.description";
            break;
        default:
            throw new Error("type inconnu");
    }
    return libelle;
}



/**
 *
 * @param {Object} notification
 * @return {Object} author or empty object
 */
export const getAuthor=(notification:any) =>{
    return get(notification, "sourceUser", {});
}

/**
 *
 * @param {Object} notification
 * @return {Boolean} is anonymous user
 */
export const isAnonymous=(notification:any)=> {
    return get(notification, "sourceUser.anonymous", false);
}

/**
 *
 * @param {Object} notification
 * @return {String} group name or empty string
 */
export const getGroup=(notification:any)=> {
    return capitalize(get(notification, "groupe.name", ""));
}

/**
 *
 * @param {Object} notification
 * @return {String} badge title
 */
export const getBadge=(notification:any)=> {
    return capitalize(get(notification, "badge.title", ""));
}

/**
 *
 * @param {Object} notification
 * @return {String} parcour title
 */
export const getParcours=(notification:any)=> {
    return get(notification, "parcours", {});
}

/**
 *
 * @param {Object} notification
 * @return {Object} commenting user
 */
export const getCommentingUser=(notification:any) =>{
    return get(notification, "commentaire.user", {});
}

/**
 *
 * @param {Object} notification
 * @return {Object} abuser user
 */
export const getAbuserUser=(notification:any)=> {
    return get(notification, "speach.author", {});
}

/**
 *
 * @param {Object} notification
 * @return {String} topic title capitalized
 */
export const getTopic=(notification:any)=> {
    return capitalize(get(notification, "topic.title", ""));
}
