import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-790c18ec"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dialogMaxHeight"
};
const _hoisted_2 = {
  class: "dialogShrinkedWhenScrollbarOn"
};
const _hoisted_3 = {
  class: "labelBold"
};
const _hoisted_4 = {
  class: "labelBold"
};
const _hoisted_5 = {
  class: "labelBold"
};
const _hoisted_6 = {
  class: "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_alert = _resolveComponent("w-alert");
  const _component_w_select_users = _resolveComponent("w-select-users");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_w_select_advanced = _resolveComponent("w-select-advanced");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  const _component_standard_button = _resolveComponent("standard-button");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createBlock(_component_el_form, {
    model: _ctx.fieldsToUpdate,
    "label-width": "auto",
    "label-position": "top",
    style: {
      "max-width": "100%"
    }
  }, {
    default: _withCtx(() => [_ctx.listScheduledSpeaches.length >= 1 ? (_openBlock(), _createBlock(_component_w_alert, {
      key: 0,
      description: _ctx.$tc('admin.dashboard.speaches.batchUpdate.speachesScheduled', _ctx.listScheduledSpeaches.length, {
        nbSpeaches: _ctx.listScheduledSpeaches.length
      }),
      type: "warning",
      closable: false,
      "show-icon": true
    }, null, 8, ["description"])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_scrollbar, {
      "max-height": "410px"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_form_item, null, {
        label: _withCtx(() => [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('admin.dashboard.speaches.search.title.author')), 1)]),
        default: _withCtx(() => [_createVNode(_component_w_select_users, {
          class: "selectFullWidth",
          onChange: _ctx.onChangeAuthor,
          value: _ctx.fieldsToUpdate.author,
          placeholder: _ctx.$t('admin.dashboard.speaches.batchUpdate.placeholders.author'),
          "value-key": "id",
          "label-key": "email",
          "role-filter": "FORMATEUR"
        }, null, 8, ["onChange", "value", "placeholder"])]),
        _: 1
      }), _createVNode(_component_el_form_item, null, {
        label: _withCtx(() => [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('admin.dashboard.speaches.search.title.visibility')), 1)]),
        default: _withCtx(() => [_createVNode(_component_w_select_advanced, {
          options: _ctx.availableVisibilities,
          class: "selectFullWidth",
          onChange: _ctx.onChangeVisibility,
          value: _ctx.fieldsToUpdate.visibility,
          placeholder: _ctx.$t('admin.dashboard.speaches.batchUpdate.placeholders.visibility'),
          "value-key": "id",
          "label-key": "name"
        }, null, 8, ["options", "onChange", "value", "placeholder"])]),
        _: 1
      }), _createVNode(_component_el_form_item, null, {
        label: _withCtx(() => [_createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('admin.dashboard.speaches.search.title.category')), 1)]),
        default: _withCtx(() => [_createVNode(_component_w_select_advanced, {
          options: _ctx.availableCategories,
          class: "selectFullWidth",
          onChange: _ctx.onChangeCategory,
          value: _ctx.fieldsToUpdate.categoryId,
          placeholder: _ctx.$t('admin.dashboard.speaches.batchUpdate.placeholders.category'),
          "value-key": "id",
          "label-key": "name"
        }, null, 8, ["options", "onChange", "value", "placeholder"])]),
        _: 1
      })])]),
      _: 1
    })]), _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createVNode(_component_standard_button, {
        outlined: true,
        label: _ctx.$t('admin.dashboard.users.sidebar.deactivation.cancel'),
        onClick: _ctx.cancelBatchUpdate
      }, null, 8, ["label", "onClick"]), _createVNode(_component_standard_button, {
        label: _ctx.$t('admin.dashboard.users.sidebar.deactivation.ok'),
        onClick: _ctx.submitBatchUpdate
      }, null, 8, ["label", "onClick"])])]),
      _: 1
    })]),
    _: 1
  }, 8, ["model"]);
}