import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-cb942918"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "speachDetails"
};
const _hoisted_2 = {
  class: "scrollableSpeachDetails"
};
const _hoisted_3 = {
  class: "row"
};
const _hoisted_4 = ["src", "title"];
const _hoisted_5 = {
  class: "row"
};
const _hoisted_6 = {
  class: "speachTitle"
};
const _hoisted_7 = {
  key: 0,
  class: "row"
};
const _hoisted_8 = {
  class: "secondaryInfo"
};
const _hoisted_9 = {
  key: 1,
  class: "row"
};
const _hoisted_10 = {
  class: "secondaryInfo"
};
const _hoisted_11 = {
  key: 2,
  class: "row"
};
const _hoisted_12 = {
  class: "secondaryInfo"
};
const _hoisted_13 = {
  class: "row"
};
const _hoisted_14 = {
  class: "menuDelete"
};
const _hoisted_15 = {
  class: "row"
};
const _hoisted_16 = {
  class: "column"
};
const _hoisted_17 = {
  class: "subTitle"
};
const _hoisted_18 = {
  class: "column"
};
const _hoisted_19 = {
  class: "subTitle"
};
const _hoisted_20 = {
  class: "row"
};
const _hoisted_21 = {
  class: "column"
};
const _hoisted_22 = {
  class: "subTitle"
};
const _hoisted_23 = {
  class: "row"
};
const _hoisted_24 = {
  class: "column"
};
const _hoisted_25 = {
  class: "subTitle"
};
const _hoisted_26 = {
  class: "row"
};
const _hoisted_27 = {
  class: "column"
};
const _hoisted_28 = {
  class: "subTitle"
};
const _hoisted_29 = {
  class: "column"
};
const _hoisted_30 = {
  class: "subTitle"
};
const _hoisted_31 = {
  key: 0,
  class: "row"
};
const _hoisted_32 = {
  class: "column justified"
};
const _hoisted_33 = {
  class: "pinned-label-icon"
};
const _hoisted_34 = {
  class: "subTitle"
};
const _hoisted_35 = {
  key: 1,
  class: "row"
};
const _hoisted_36 = {
  class: "column"
};
const _hoisted_37 = {
  class: "subTitle"
};
const _hoisted_38 = {
  key: 2
};
const _hoisted_39 = {
  class: "row"
};
const _hoisted_40 = {
  class: "column"
};
const _hoisted_41 = {
  class: "subTitle"
};
const _hoisted_42 = {
  class: "row"
};
const _hoisted_43 = {
  class: "column"
};
const _hoisted_44 = {
  class: "subTitle"
};
const _hoisted_45 = {
  key: 3,
  class: "row"
};
const _hoisted_46 = {
  class: "column justified"
};
const _hoisted_47 = {
  class: "subTitle"
};
const _hoisted_48 = {
  key: 4,
  class: "row"
};
const _hoisted_49 = {
  class: "column justified"
};
const _hoisted_50 = {
  class: "subTitle"
};
const _hoisted_51 = {
  class: "row"
};
const _hoisted_52 = {
  class: "column"
};
const _hoisted_53 = {
  class: "subTitle"
};
const _hoisted_54 = {
  class: "row"
};
const _hoisted_55 = {
  class: "column"
};
const _hoisted_56 = {
  class: "subTitle"
};
const _hoisted_57 = {
  class: "row"
};
const _hoisted_58 = {
  class: "column"
};
const _hoisted_59 = {
  class: "subTitle"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_tool_tip = _resolveComponent("w-tool-tip");
  const _component_standard_button = _resolveComponent("standard-button");
  const _component_icon = _resolveComponent("icon");
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  const _component_el_dropdown = _resolveComponent("el-dropdown");
  const _component_w_select_advanced = _resolveComponent("w-select-advanced");
  const _component_w_text = _resolveComponent("w-text");
  const _component_w_toggle = _resolveComponent("w-toggle");
  const _component_w_input = _resolveComponent("w-input");
  const _component_w_select_users = _resolveComponent("w-select-users");
  const _component_w_tag = _resolveComponent("w-tag");
  const _component_w_date = _resolveComponent("w-date");
  const _component_el_tab_pane = _resolveComponent("el-tab-pane");
  const _component_el_tabs = _resolveComponent("el-tabs");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  const _component_scorm_export_dialog = _resolveComponent("scorm-export-dialog");
  const _component_confirm_dialog = _resolveComponent("confirm-dialog");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_scrollbar, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_ctx.visible ? (_openBlock(), _createElementBlock("iframe", {
      key: 0,
      class: "embed-video",
      src: '/play-embedded/' + _ctx.speach.speachID + '?internalPreview=true',
      width: "575px",
      height: "391px",
      title: _ctx.speach.title
    }, null, 8, _hoisted_4)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_w_tool_tip, {
      text: _ctx.speach.title
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.speach.title), 1)]),
      _: 1
    }, 8, ["text"])]), _ctx.speach.publicationTimestamp ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('admin.dashboard.speaches.lastPublishedOn') + ' ' + _ctx.$d(new Date(_ctx.speach.publicationTimestamp))), 1)])) : _createCommentVNode("", true), _ctx.speach.lastUpdatedTimestamp ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('admin.dashboard.speaches.lastEditedOn') + ' ' + _ctx.$d(new Date(_ctx.speach.lastUpdatedTimestamp))), 1)])) : _createCommentVNode("", true), _ctx.speach.delayedTillTimestamp ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('admin.dashboard.speaches.delayedTill') + ' ' + _ctx.$d(new Date(_ctx.speach.delayedTillTimestamp))), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_13, [_createVNode(_component_standard_button, {
      class: "column standardButton",
      label: _ctx.$t('admin.dashboard.speaches.sidebar.viewSpeach'),
      expanded: true,
      onClick: _ctx.goToSpeach
    }, null, 8, ["label", "onClick"]), _createVNode(_component_standard_button, {
      class: "column standardButton",
      label: _ctx.$t('admin.dashboard.speaches.sidebar.edit'),
      expanded: true,
      outlined: true,
      onClick: _ctx.goToSpeachEdit
    }, null, 8, ["label", "onClick"]), _createVNode(_component_el_dropdown, {
      trigger: "click",
      placement: "bottom-end"
    }, {
      dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
        default: _withCtx(() => [_ctx.speach.state !== _ctx.SpeachState.NON_PUBLIE || _ctx.isDelayed(_ctx.speach) ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
          key: 0,
          onClick: _ctx.shareSpeach
        }, {
          default: _withCtx(() => [_createVNode(_component_icon, {
            icon: "share",
            class: "iconMenu"
          }), _createTextVNode("  " + _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.more.share')), 1)]),
          _: 1
        }, 8, ["onClick"])) : _createCommentVNode("", true), _ctx.speach.state !== _ctx.SpeachState.NON_PUBLIE ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
          key: 1,
          onClick: _ctx.goToMonitorSpeach
        }, {
          default: _withCtx(() => [_createVNode(_component_icon, {
            icon: "chart-bar",
            class: "iconMenu"
          }), _createTextVNode("  " + _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.more.analytics')), 1)]),
          _: 1
        }, 8, ["onClick"])) : _createCommentVNode("", true), _createVNode(_component_el_dropdown_item, {
          onClick: _ctx.duplicateSpeach
        }, {
          default: _withCtx(() => [_createVNode(_component_icon, {
            icon: "content-copy",
            class: "iconMenu"
          }), _createTextVNode("  " + _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.more.duplicate')), 1)]),
          _: 1
        }, 8, ["onClick"]), _createVNode(_component_el_dropdown_item, {
          onClick: _ctx.createTemplate,
          "data-test": "speach-details-create-template"
        }, {
          default: _withCtx(() => [_createVNode(_component_icon, {
            icon: "clipboard-multiple-outline",
            class: "iconMenu"
          }), _createTextVNode("  " + _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.more.template')), 1)]),
          _: 1
        }, 8, ["onClick"]), _ctx.speach.state !== _ctx.SpeachState.NON_PUBLIE ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
          key: 2,
          onClick: _cache[0] || (_cache[0] = $event => _ctx.scormExportPopup = true),
          "data-test": "speach-details-export-scorm"
        }, {
          default: _withCtx(() => [_createVNode(_component_icon, {
            icon: "download-outline",
            class: "iconMenu"
          }), _createTextVNode("  " + _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.more.scorm')), 1)]),
          _: 1
        })) : _createCommentVNode("", true), _ctx.speach.isWorkflowInProgress ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
          key: 3,
          onClick: _ctx.cancelWorkflow
        }, {
          default: _withCtx(() => [_createVNode(_component_icon, {
            icon: "cancel",
            class: "iconMenu"
          }), _createTextVNode("  " + _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.more.cancelWorkflow')), 1)]),
          _: 1
        }, 8, ["onClick"])) : _createCommentVNode("", true), _createVNode(_component_el_dropdown_item, {
          onClick: _ctx.archiveSpeach,
          "data-test": "speach-details-archive"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_14, [_createVNode(_component_icon, {
            icon: "delete-outline",
            class: "iconMenu"
          }), _createTextVNode("  " + _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.more.archive')), 1)])]),
          _: 1
        }, 8, ["onClick"])]),
        _: 1
      })]),
      default: _withCtx(() => [_createVNode(_component_standard_button, {
        class: "standardButton",
        label: "...",
        outlined: true,
        "very-small-margins": true,
        "data-test": "speach-details-more-actions"
      }, {
        default: _withCtx(() => [_createVNode(_component_icon, {
          icon: "dots-horizontal",
          size: "small"
        })]),
        _: 1
      })]),
      _: 1
    })]), _createVNode(_component_el_tabs, {
      class: "tabs",
      modelValue: _ctx.selectedTab,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.selectedTab = $event)
    }, {
      default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
        name: "GENERAL",
        label: _ctx.$t('admin.dashboard.speaches.sidebar.tabs.general.titleTab')
      }, {
        default: _withCtx(() => {
          var _ctx$speach$category;
          return [_createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.tabs.general.category')), 1), _createVNode(_component_w_select_advanced, {
            options: _ctx.availableCategories,
            class: "selectWithoutTags",
            onChange: _ctx.onChangeCategory,
            value: (_ctx$speach$category = _ctx.speach.category) === null || _ctx$speach$category === void 0 ? void 0 : _ctx$speach$category.id,
            "value-key": "id",
            "label-key": "name",
            "class-click-filter": _ctx.classClickFilter
          }, null, 8, ["options", "onChange", "value", "class-click-filter"])]), _createElementVNode("div", _hoisted_18, [_createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.tabs.general.language')), 1), _createVNode(_component_w_text, {
            class: "label-read-only"
          }, {
            default: _withCtx(() => {
              var _ctx$speach$language;
              return [_createTextVNode(_toDisplayString((_ctx$speach$language = _ctx.speach.language) !== null && _ctx$speach$language !== void 0 ? _ctx$speach$language : "-"), 1)];
            }),
            _: 1
          })])]), _createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, [_createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.tabs.general.tags')), 1), _createVNode(_component_w_text, {
            class: "label-read-only"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.speach.tags), 1)]),
            _: 1
          })])]), _createElementVNode("div", _hoisted_23, [_createElementVNode("div", _hoisted_24, [_createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.tabs.general.description')), 1), _createVNode(_component_w_text, {
            class: "label-read-only",
            "data-test": "speach-details-description-text"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.speach.description), 1)]),
            _: 1
          })])]), _createElementVNode("div", _hoisted_26, [_createElementVNode("div", _hoisted_27, [_createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.tabs.general.state')), 1), _createVNode(_component_w_select_advanced, {
            options: _ctx.availableStates,
            class: "selectWithoutTags",
            onChange: _ctx.onChangeState,
            value: _ctx.speach.state,
            "value-key": "id",
            "label-key": "name",
            "class-click-filter": _ctx.classClickFilter
          }, null, 8, ["options", "onChange", "value", "class-click-filter"])]), _createElementVNode("div", _hoisted_29, [_createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.tabs.general.visibility')), 1), _createVNode(_component_w_select_advanced, {
            options: _ctx.availableVisibilities,
            class: "selectWithoutTags",
            onChange: _ctx.onChangeVisibility,
            value: _ctx.speach.visibility,
            "value-key": "id",
            "label-key": "name",
            "class-click-filter": _ctx.classClickFilter,
            "data-test": "speach-details-visibility-selection"
          }, null, 8, ["options", "onChange", "value", "class-click-filter"])])]), _ctx.speach.visibility === _ctx.VisibilityEnum.PUBLIC && _ctx.speach.state === _ctx.SpeachState.PUBLIE ? (_openBlock(), _createElementBlock("div", _hoisted_31, [_createElementVNode("div", _hoisted_32, [_createElementVNode("div", _hoisted_33, [_createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.tabs.general.pinned.label')), 1), _createVNode(_component_w_tool_tip, {
            text: _ctx.$t('admin.dashboard.speaches.sidebar.tabs.general.pinned.message')
          }, {
            default: _withCtx(() => [_createVNode(_component_icon, {
              icon: "information-outline",
              size: "small",
              color: "light2",
              "data-test": "pinned-on-home-information"
            })]),
            _: 1
          }, 8, ["text"])]), _createVNode(_component_w_toggle, {
            value: _ctx.speach.isPinnedOnHome,
            modelValue: _ctx.speach.isPinnedOnHome,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.speach.isPinnedOnHome = $event),
            onChange: _ctx.onChangePinned
          }, null, 8, ["value", "modelValue", "onChange"])])])) : _createCommentVNode("", true), _ctx.speach.visibility === _ctx.VisibilityEnum.PRIVE ? (_openBlock(), _createElementBlock("div", _hoisted_35, [_createElementVNode("div", _hoisted_36, [_createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.tabs.general.accessCode')), 1), _createVNode(_component_w_input, {
            value: _ctx.speach.accessCode,
            "no-prefix": true,
            onChangeInput: _cache[2] || (_cache[2] = $event => _ctx.onChangeAccessCode($event)),
            maxlength: "230"
          }, null, 8, ["value"])])])) : _createCommentVNode("", true), _ctx.speach.visibility === _ctx.VisibilityEnum.RESTREINT ? (_openBlock(), _createElementBlock("div", _hoisted_38, [_createElementVNode("div", _hoisted_39, [_createElementVNode("div", _hoisted_40, [_createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.tabs.general.users')), 1), _createVNode(_component_w_select_users, {
            onChange: _ctx.onChangeAffectedUsers,
            class: "selectWithoutTags",
            value: _ctx.speach.affectationUsersInfos.affectedUsers,
            "value-key": "id",
            "label-key": "email",
            multiple: true,
            placeholder: _ctx.$t('admin.dashboard.speaches.sidebar.tabs.authors.placeholderUsers'),
            "class-click-filter": _ctx.classClickFilter,
            "data-test": "add-users-select"
          }, null, 8, ["onChange", "value", "placeholder", "class-click-filter"]), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.speach.affectationUsersInfos.affectedUsers, user => {
            return _openBlock(), _createBlock(_component_w_tag, {
              textTag: user.label,
              key: user.id,
              round: "",
              onRemoveTag: $event => _ctx.deleteOneAffectedUser(user)
            }, null, 8, ["textTag", "onRemoveTag"]);
          }), 128))])])]), _createElementVNode("div", _hoisted_42, [_createElementVNode("div", _hoisted_43, [_createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.tabs.general.groups')), 1), _createVNode(_component_w_select_advanced, {
            options: _ctx.availableGroups,
            class: "selectWithoutTags",
            onChange: _ctx.onChangeAffectedGroups,
            value: _ctx.affectedGroupsIds,
            "value-key": "id",
            "label-key": "name",
            filterable: true,
            multiple: true,
            placeholder: _ctx.$t('admin.dashboard.speaches.sidebar.tabs.authors.placeholderGroups'),
            "class-click-filter": _ctx.classClickFilter
          }, null, 8, ["options", "onChange", "value", "placeholder", "class-click-filter"]), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.speach.affectationUsersInfos.affectedGroups, group => {
            return _openBlock(), _createBlock(_component_w_tag, {
              textTag: group.label,
              key: group.id,
              round: "",
              onRemoveTag: $event => _ctx.deleteOneAffectedGroup(group.id)
            }, null, 8, ["textTag", "onRemoveTag"]);
          }), 128))])])])])) : _createCommentVNode("", true), _ctx.speachDueDateIsSet ? (_openBlock(), _createElementBlock("div", _hoisted_45, [_createElementVNode("div", _hoisted_46, [_createElementVNode("span", _hoisted_47, _toDisplayString(_ctx.$t('speach.metadata.dueDate.paramDueDate')), 1), _createVNode(_component_w_date, {
            class: "label-read-only text-read-only",
            "string-date": _ctx.speach.dueDate
          }, null, 8, ["string-date"])])])) : _createCommentVNode("", true), _ctx.speachDueDateIsSet ? (_openBlock(), _createElementBlock("div", _hoisted_48, [_createElementVNode("div", _hoisted_49, [_createElementVNode("span", _hoisted_50, _toDisplayString(_ctx.$t('speach.metadata.dueDate.unpublish')), 1), _createVNode(_component_w_toggle, {
            value: _ctx.speach.unpublishAfterDueDate,
            onChange: _ctx.changeUnpublishAfterDueDate,
            disabled: true
          }, null, 8, ["value", "onChange"])])])) : _createCommentVNode("", true)];
        }),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_tab_pane, {
        name: "AUTHORS",
        label: _ctx.$t('admin.dashboard.speaches.sidebar.tabs.authors.titleTab')
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_51, [_createElementVNode("div", _hoisted_52, [_createElementVNode("span", _hoisted_53, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.tabs.authors.author')), 1), _createVNode(_component_w_select_users, {
          class: "selectWithoutTags",
          onChange: _ctx.onChangeAuthor,
          value: _ctx.speach.authorsInfos.author,
          "value-key": "id",
          "label-key": "email",
          "role-filter": "FORMATEUR",
          "class-click-filter": _ctx.classClickFilter
        }, null, 8, ["onChange", "value", "class-click-filter"])])]), _createElementVNode("div", _hoisted_54, [_createElementVNode("div", _hoisted_55, [_createElementVNode("span", _hoisted_56, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.tabs.authors.coAuthorsUsers')), 1), _createVNode(_component_w_select_users, {
          onChange: _ctx.onChangeCoAuthors,
          class: "selectWithoutTags",
          value: _ctx.speach.authorsInfos.coEditors,
          "value-key": "id",
          "label-key": "email",
          multiple: true,
          "role-filter": "FORMATEUR",
          placeholder: _ctx.$t('admin.dashboard.speaches.sidebar.tabs.authors.placeholderUsers'),
          "class-click-filter": _ctx.classClickFilter
        }, null, 8, ["onChange", "value", "placeholder", "class-click-filter"]), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.speach.authorsInfos.coEditors, coAuthor => {
          return _openBlock(), _createBlock(_component_w_tag, {
            textTag: coAuthor.label,
            key: coAuthor.id,
            round: "",
            onRemoveTag: $event => _ctx.deleteOneCoAuthor(coAuthor)
          }, null, 8, ["textTag", "onRemoveTag"]);
        }), 128))])])]), _createElementVNode("div", _hoisted_57, [_createElementVNode("div", _hoisted_58, [_createElementVNode("span", _hoisted_59, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.tabs.authors.coAuthorsGroups')), 1), _createVNode(_component_w_select_advanced, {
          options: _ctx.availableGroups,
          class: "selectWithoutTags",
          onChange: _ctx.onChangeCoGroups,
          value: _ctx.coGroupsIds,
          "value-key": "id",
          "label-key": "name",
          filterable: true,
          multiple: true,
          placeholder: _ctx.$t('admin.dashboard.speaches.sidebar.tabs.authors.placeholderGroups'),
          "class-click-filter": _ctx.classClickFilter
        }, null, 8, ["options", "onChange", "value", "placeholder", "class-click-filter"]), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.speach.authorsInfos.coEditorGroups, coGroup => {
          return _openBlock(), _createBlock(_component_w_tag, {
            textTag: coGroup.label,
            key: coGroup.id,
            round: "",
            onRemoveTag: $event => _ctx.deleteOneCoGroup(coGroup.id)
          }, null, 8, ["textTag", "onRemoveTag"]);
        }), 128))])])])]),
        _: 1
      }, 8, ["label"])]),
      _: 1
    }, 8, ["modelValue"])])]),
    _: 1
  }), _createVNode(_component_scorm_export_dialog, {
    speach: _ctx.speach,
    visible: _ctx.scormExportPopup,
    "scorm-classic-enabled": _ctx.scormClassicEnabled,
    onClosePopup: _cache[4] || (_cache[4] = $event => _ctx.scormExportPopup = false)
  }, null, 8, ["speach", "visible", "scorm-classic-enabled"]), _createVNode(_component_confirm_dialog, {
    title: _ctx.$t('admin.dashboard.speaches.templates.popup.title'),
    message: _ctx.$t('admin.dashboard.speaches.templates.popup.message'),
    "confirm-button-text": _ctx.$t('admin.dashboard.speaches.templates.popup.goToTemplates'),
    "cancel-button-text": _ctx.$t('admin.dashboard.speaches.templates.popup.stay'),
    visible: _ctx.goToTemplatesPopup,
    centered: false,
    onOnDialogClose: _cache[5] || (_cache[5] = $event => _ctx.goToTemplatesPopup = false),
    onOnCanceled: _cache[6] || (_cache[6] = $event => _ctx.goToTemplatesPopup = false),
    onOnConfirmed: _ctx.goToTemplates,
    "data-test": "speach-details-create-template-popup"
  }, null, 8, ["title", "message", "confirm-button-text", "cancel-button-text", "visible", "onOnConfirmed"])]);
}