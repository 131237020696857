/**
 * Allow to communicate with legacy front (jQuery)
 */
export enum Events{
    DIFFUSER_COUR="DIFFUSER_COUR", // Open the share panel
    DUPLICATE_COUR_THUMBNAIL="DUPLICATE_COUR_THUMBNAIL", // Dupplicate a speach
    MOUNT_VUE_COMPONENT="MOUNT_VUE_COMPONENT",// Mount a vue component
    OPEN_POPIN_IMAGE="OPEN_POPIN_IMAGE",  // Open the panel to modify an image
    AFFICHER_EDITION_CHAPITRE="AFFICHER_EDITION_CHAPITRE", // Show the chapter Editing page
    AFFICHER_CHOIX_TYPE_CHAPITRE='AFFICHER_CHOIX_TYPE_CHAPITRE',// Show the choice of type of media
    RAFRAICHIR_PLAN_CHAPITRE="RAFRAICHIR_PLAN_CHAPITRE", // Refresh the section of the chapter to refresh the validation point
    SELECT_OLD_VIDEO_UI_CHAPTER="SELECT_OLD_VIDEO_UI_CHAPTER", // Ask to switch to old chapter editing instead of WeetChaptre.vue
    UPDATE_SHARABLE_SPEACH_LINKS = "UPDATE_SHARABLE_SPEACH_LINKS",
    RESET_SHARABLE_SPEACH_LINKS = "RESET_SHARABLE_SPEACH_LINKS",

    // VIDEO PLAYER
    VIDEO_AUDIO_TRACK_SELECTED = "VIDEO_AUDIO_TRACK_SELECTED",
    RESET_AUDIO_TRACK = "RESET_AUDIO_TRACK",
    SELECT_AUDIO_TRACK='SELECT_AUDIO_TRACK',
    VIDEO_PLAYING="VIDEO_PLAYING",
    VIDEO_READY="VIDEO_READY",
    VIDEO_PAUSING="VIDEO_PAUSING",
    VIDEO_ENDED="VIDEO_ENDED",
    LOAD_ETAPE="LOAD_ETAPE",


    // DUE DATE SETTINGS
    BADGE_SET = "BADGE_SET",
    BADGE_UNSET = "BADGE_UNSET",
    DUE_DATE_EXPIRED_SET = "DUE_DATE_EXPIRED_SET",
    DUE_DATE_EXPIRED_UNSET = "DUE_DATE_EXPIRED_UNSET",

    // GENERATE AI AUDIO FROM TEXT
    SAVE_AUDIO_FROM_TEXT = "SAVE_AUDIO_FROM_TEXT",
    CLOSE_AI_AUDIO_POPIN = "CLOSE_AI_AUDIO_POPIN",
}
